import React from "react";
import "./App.css";
import Person from "./Person";

function App() {
  return (
    <div className="App">
      <h1>
        Dr.<span className="resp-hidden"> Felix</span> Sehorz & Mag.
        <span className="resp-hidden"> Martin</span> Dohnal
        <br />
        <small>Selbstständige Rechtsanwälte</small>
      </h1>
      <main>
        <section className="persons">
          <div>
            <Person title="Mag. Dr. Felix Sehorz">
              <p>Rechtsanwalt</p>
              <p>
                Wollzeile 24
                <br />
                1010 Wien
              </p>
              <p>
                Telefon +431 585-58-97
                <br />
                Fax +431 585-58-97 18
                <br />
                office@sedo.co.at
                <br />
                www.sehorz-dohnal.at
              </p>
              <p>
                ADVM (RA)-Code R 136671
                <br />
                UID ATU59853199
              </p>
              <p>
                IBAN AT60 2011 1310 1550 1152
                <br />
                BIC GIBAATWW
                <br />
                Erste Bank der oesterreichischen Sparkassen AG
              </p>
            </Person>
          </div>
          <div className="resp-margin-top">
            <Person title="Mag. Martin Dohnal">
              <p>
                Rechtsanwalt
                <br />
                Verteidiger in Strafsachen
              </p>
              <p>
                Wollzeile 24
                <br />
                1010 Wien
              </p>
              <p>
                Telefon +431 513 80 70
                <br />
                Fax +431 513 80 70 18
                <br />
                dohnal@sedo.co.at
                <br />
                www.sehorz-dohnal.at
              </p>
              <p>
                ADVM (RA)-Code R139696
                <br />
                UID ATU57680888
              </p>
              <p>
                IBAN AT24 1500 0005 0139 4522
                <br />
                BIC OBKLAT2L
                <br />
                Oberbank AG
              </p>
            </Person>
          </div>
        </section>
        <section className="imp">
          <h3>Impressum</h3>
          <p>
            Erfüllung der Informationspflicht nach § 5 Absatz 1
            E-Commerce-Gesetz:
          </p>
          <p>
            Mag. Dr. Felix Sehorz, Telefon +431 585-58-97, Fax +431 585-58-97
            18, office@sedo.co.at, www.sehorz-dohnal.at, ADVM (RA)-Code R
            136671, UID ATU59853199, IBAN AT60 2011 1310 1550 1152, BIC
            GIBAATWW, Erste Bank der oesterreichischen Sparkassen AG
          </p>
          <p>
            Mag. Martin Dohnal, Wollzeile 24, 1010 Wien, Telefon +431 513 80 70,
            Fax +431 513 80 70 18, dohnal@sedo.co.at, www.sehorz-dohnal.at, ADVM
            (RA)-Code R139696, UID ATU57680888, IBAN AT90 1500 0005 0139 4498,
            BIC OBKLAT2L, Oberbank AG
          </p>
          <p>
            Die Rechtsanwälte Mag. Dr. Felix Sehorz und Mag. Martin Dohnal sind
            Mitglieder der Rechtsanwaltskammer Wien. Die Bezeichnung als
            Rechtsanwalt bedarf keiner Ernennung, sondern des Nachweises
            bestimmter Erfordernisse und der Eintragung in die Liste der
            Rechtsanwälte (§ 1 RAO).
          </p>
          <p>
            Die Rechtsanwälte Mag. Dr. Felix Sehorz und Mag. Martin Dohnal
            unterliegen, dem Standesrecht der Rechtsanwälte (RAO, RL-BA, EuRAG),
            dessen Einhaltung durch die zuständigen Organe der
            Rechtsanwaltskammer Wien überwacht wird. Die einschlägigen
            Gesetzlichen Regelungen des Rechtes der Rechtsanwälte sind einfach
            über die Websites des Österreichischen Rechtsanwaltskammertages
            www.oerak.at und der Rechtsanwaltskammer Wien www.rakwien.at
            abzurufen.
          </p>
          <p>Offenlegung nach § 25 Absatz 2 Mediengesetz:</p>
          <p>
            Medieninhaber sind die sie Rechtsanwälte Mag. Dr. Felix Sehorz und
            Mag. Martin Dohnal mit Sitz in Wien. Ihr Unternehmensgegenstand ist
            jeweils die selbstständige Ausübung der Rechtsanwaltschaft
            einschließlich der erforderlichen Hilfstätigkeiten.
          </p>
          <p>Offenlegung nach § 25 Absatz 4 Mediengesetz:</p>
          <p>
            Diese Website richtet sich an bestehende und zukünftige Mandanten.
            Inhalt dieser Website ist die Präsentation der Rechtsanwälte Mag.
            Dr. Felix Sehorz und Mag. Martin Dohnal.
          </p>
        </section>
      </main>
    </div>
  );
}

export default App;
