import React, { useState } from "react";
import "./App.css";

interface IProps {
  title: string;
  children?: React.ReactNode;
}

function Person(props: IProps) {
  const [respVisible, setRespVisible] = useState(false);
  return (
    <div className="resp-border">
      <h2 onClick={() => setRespVisible(!respVisible)}>
        {props.title}{" "}
        <span className="float-right desk-hidden">
          {respVisible ? "\u25B3" : "\u25BD"}
        </span>
      </h2>
      <div className={!respVisible ? "resp-hidden" : ""}>{props.children}</div>
    </div>
  );
}

export default Person;
